import * as React from "react";
import Helmet from "react-helmet";

interface HeadProps {
  title: string;
  imageSrc: string;
}

export function Head(props: HeadProps) {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta property="og:title" content={props.title} />
      <meta property="og:image" content={props.imageSrc} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />
    </Helmet>
  )
}