import React from "react"

import styles from "./ParallaxHooklessImage.module.css"

export interface ParallaxImageProps {
  src: string;
  height: number;
  width: number;
  children?: React.ReactNode
}

export interface ParallaxHooklessImageState {
  blur: boolean
  showLowFidelity: boolean
  dataUrl?: string
}

//const maxOffset = 250

export default class ParallaxHooklessImage extends React.Component<
  ParallaxImageProps,
  ParallaxHooklessImageState
  > {
  containerRef: React.RefObject<HTMLDivElement> = React.createRef()
  imageRef: React.RefObject<HTMLDivElement> = React.createRef()
  timestamp = Date.now()
  offset: number = 0;
  defaultTop: number = 0;
  height: number = 0;
  constructor(props: ParallaxImageProps) {
    super(props)

    this.state = {
      blur: false,//(props.base64 && true) || false,
      showLowFidelity: false,// (props.base64 && true) || false,
    }

    //this.getImage = this.getImage.bind(this)
    this.onScroll = this.onScroll.bind(this)
    this.onResize = this.onResize.bind(this);
    this.onRequestAnimation = this.onRequestAnimation.bind(this)
  }

  componentDidMount() {
    var isChrome = !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent) && /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    if (!isChrome) {
      return;
    }

    this.onResize()
    this.onRequestAnimation()
    window.addEventListener("scroll", this.onScroll)
    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll)
    window.removeEventListener("resize", this.onResize)
  }

  onRequestAnimation() {
    const innerHeight = window.innerHeight
    if (!this.imageRef.current) {
      console.log("Missing");
      return;
    }
    //const value = window.scrollY - 2;

    const top = this.defaultTop - window.scrollY;
    const bottom = this.defaultTop + this.height - window.scrollY;

    if (top < innerHeight + 10 && bottom > -10) {
      const halfHeight = 0.5 * this.height
      const total = innerHeight + this.height

      this.offset = halfHeight - this.height * (bottom / total)
      this.imageRef.current.style.transform = `translate3d(0px, ${this.offset}px, 0px)`
    }
  }

  onScroll() {
    this.onRequestAnimation()
    requestAnimationFrame(this.onRequestAnimation)
  }

  onResize() {
    if (!this.containerRef.current) {
      return;
    }
    const { top, height } = this.containerRef.current.getBoundingClientRect();
    this.defaultTop = window.scrollY + top;
    this.height = height;
  }

  render() {
    return (
      <div
        ref={this.containerRef}
        className={styles.container}
        style={{
          height: "calc(100vh - 61px)",
        }}
      >
        <div className={styles.childrenContainer}>{this.props.children}</div>
        <div
          className={`${styles.blurDiv} ${this.state.blur ? styles.blur : ""}`}
        >
          <div className={styles.offsetContainer}
            ref={this.imageRef}
            style={{
              transform: `translate3d(0px, ${this.offset}px, 0px)`
            }}>
            <img
              onLoad={() => {
                this.setState({
                  showLowFidelity: false,
                  blur: false
                });
              }}
              className={styles.image}
              src={this.props.src}
              height={this.props.height}
              width={this.props.width}
            />
          </div>
        </div>
      </div>
    )
  }
}
