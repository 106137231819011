import React, { MutableRefObject } from "react"

import styles from "./TextBlock.module.css"

export default React.forwardRef<HTMLDivElement, { children: React.ReactNode; height?: string; disablePadding?: boolean; }>((props, ref) => {
  return (
    <div
      ref={ref}
      style={{
        minHeight: props.height,
        paddingLeft: props.disablePadding && "0px" || "10px",
        paddingRight: props.disablePadding && "0px" || "10px",
        width: props.disablePadding && "100%" || "calc(100% - 20px)"
      }}
      className={styles.textBlock}
    >
      <div className={styles.textBlockContent}>{props.children}</div>
    </div>
  )
});
